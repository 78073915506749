<template>
  <div class="user-content">
    <div class="scrollbar">
      <div class="bold">
        <h2>我的会员</h2>
      </div>
      <div class="contain" v-if="dataForm.length > 0">
        <div class="contain-cont pc-cont">
          <div class="card" v-for="(item, index) in dataForm" :key="index" @click="bigDialog(item.id)"
               :class="{org: uType == 2,person: uType == 1,info: item.expired == -1}">
            <h2>{{ item.tenantName }}</h2>
            <ul class="card-info">
              <li>
                <span class="name">{{ uType == 1 ? "姓名：" : "单位名称：" }}</span>
                <span class="value">{{ uType == 1 ? item.code.realName : item.code.fullName }}</span>
              </li>
              <li v-if="uType == 1">
                <span class="name">性别：</span>
                <span class="value">{{ item.code.sex == 1 ? "男" : "女" }}</span>
              </li>
              <li v-if="uType == 2">
                <span class="name">法人名称：</span>
                <span class="value">{{ item.code.corporateName }}</span>
              </li>
              <li>
                <span class="name">会员类型：</span>
                <span class="value">{{ item.memberName }}</span>
              </li>
              <li>
                <span class="name">会员编号：</span>
                <span class="value">{{ item.memberNo?item.memberNo:'审核中' }}</span>
              </li>
              <li>
                <span class="name">会员有效期：</span>
                <template v-if="item.enabledMark == 1">
                  <span class="value" v-if="item.startTime||item.endTime">{{ toDate(item.startTime, "yyyy-MM-dd") }}-{{ toDate(item.endTime, "yyyy-MM-dd") }}</span>
                  <span class="value" v-else>审核中</span>
                </template>
                <template v-else>
                  <span class="value danger expired-soon">已过期</span>
                  <el-button size="small" class="cert-btn" @click="updatePartner(item.id, 'pay', item.tenantId,2)">立即续费</el-button>
                </template>
              </li>
              <li v-if="item.status != 4">
                <span class="name">状态：</span>
                <span class="value expired-soon" v-if="item.status == 1">待支付
                  <el-button size="small" class="cert-btn" @click.stop="gotoPay(item)" :loading="btnPay">去支付</el-button>
                </span>
                <span class="value warning" v-if="item.status == 2">审核中</span>
                <span class="value warning" v-if="item.status == 3">已取消</span>
                <template v-else-if="item.status == 5">
                  <span class="value danger expired-soon">审核失败</span>
                  <el-button size="small" class="cert-btn" @click="updatePartner(item.id, 'update', item.tenantId,1)">修改资料</el-button>
                </template>
              </li>
            </ul>
          </div>
        </div>
        <el-pagination class="page-bar" background
                       @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       :current-page="queryParams.currentPage" :page-sizes="[8, 16, 40, 80]"
                       :page-size="queryParams.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
      <div class="empty" v-else>
        <el-empty description="暂无记录"></el-empty>
      </div>
    </div>

    <el-dialog title="详情" :visible.sync="dialogVisible" center class="certificationDialog big-dialog" v-if="dialogVisible" top="8vh">
      <div class="certification-result">
        <ul class="certinfo" v-if="uType == 1">
          <li>
            <span class="cert-name">姓名：</span>
            <span class="cert-value">{{ detail.realName }}</span>
          </li>
          <li>
            <span class="cert-name">身份证：</span>
            <span class="cert-value">{{ detail.id }}</span>
          </li>
          <li>
            <span class="cert-name">性别：</span>
            <span class="cert-value">{{ detail.sex == 1 ? "男" : "女" }}</span>
          </li>
          <li>
            <span class="cert-name">联系方式：</span>
            <span class="cert-value">{{ detail.phone }}</span>
          </li>
        </ul>
        <ul class="certinfo" v-if="uType == 2">
          <li v-if="uType == 2">
            <span class="cert-name">单位名称：</span>
            <span class="cert-value">{{ detail.fullName }}</span>
          </li>
          <li v-if="uType == 2">
            <span class="cert-name">法人名称：</span>
            <span class="cert-value">{{ detail.corporateName }}</span>
          </li>
          <li v-if="uType == 2">
            <span class="cert-name">联系人：</span>
            <span class="cert-value">{{ detail.link }}</span>
          </li>
          <li v-if="uType == 2">
            <span class="cert-name">联系电话：</span>
            <span class="cert-value">{{ detail.linkPhone }}</span>
          </li>
          <li v-if="uType == 2">
            <span class="cert-name">简介：</span>
            <span class="cert-value">{{ detail.introduction }}</span>
          </li>
          <li v-if="uType == 2">
            <span class="cert-name">地址：</span>
            <span class="cert-value">{{ detail.address }}</span>
          </li>
        </ul>

        <ul class="certinfo">
          <li v-for="(item, index) in detailList" :key="index">
            <span class="cert-name">{{ item.name }}:</span>
            <span class="value" v-if="item.type !== 'upload'">{{ item.value }}</span>
            <el-image v-else style="width: 152px; height: 90px" :src="item.value[0]" :preview-src-list="item.value"></el-image>
          </li>
          <li class="config">
            <span class="cert-name">会员费：</span>
            <span class="cert-value">￥{{ config.price }}</span>
          </li>
          <li>
            <span class="cert-name">支付方式：</span>
            <span class="cert-value">{{ config.payModel == 1 ? "线上支付" : "线下支付" }}</span>
          </li>
          <li v-if="config.payModel == 2">
            <span class="cert-name">支付凭证：</span>
            <span class="cert-value">
              <el-image style="width: 152px; height: 90px" :src="loadUrl(config.offlineVoucher)" :preview-src-list="certList" />
            </span>
          </li>
          <li>
            <span class="cert-name">会员类型：</span>
            <span class="cert-value">{{ config.memberName }}</span>
          </li>
          <li>
            <span class="cert-name">会员编号：</span>
            <span class="cert-value">{{ config.memberNo?config.memberNo:'审核中' }}</span>
          </li>
          <li class="expired-time">
            <span class="cert-name">会员有效期：</span>
            <div class="expired-item">
              <span class="cert-value" v-if="config.startTime||config.endTime">{{ toDate(config.startTime, "yyyy-MM-dd") }} - {{ toDate(config.endTime, "yyyy-MM-dd") }}</span>
              <span class="cert-value" v-else>审核中</span>
            </div>
          </li>
          <li>
            <span class="cert-name">申请时间：</span>
            <span class="cert-value">{{ toDate(config.createTime, "yyyy-MM-dd") }}</span>
          </li>
          <li v-if="config.status == 1" class="expired-time">
            <span class="cert-name">状态：</span>
            <div class="expired-item">
              <span class="cert-value">待支付</span>
            </div>
          </li>
          <li v-else>
            <span class="cert-name">状态：</span>
            <span class="cert-value warning" v-if="config.status == 2">审核中</span>
            <span class="cert-value success" v-else-if="config.status == 4" >审核成功</span>
            <span class="cert-value danger" v-else-if="config.status == 5">审核失败</span>
          </li>
          <li >
            <span class="cert-name">会员证：</span>
            <div v-if="config.certificatePositive||config.certificateReverse">
              <el-image style="width: 400px; height: 250px" :src="loadUrl(config.offlineVoucher)"></el-image>
              <el-image style="width: 400px; height: 250px" :src="loadUrl(config.offlineVoucher)"></el-image>
            </div>
            <span class="cert-value" v-else>该单位尚未配置会员模板</span>
          </li>
          <li v-if="config.checkStatus == -1 && config.checkRemark">
            <span class="cert-name">不通过原因：</span>
            <span class="cert-value">{{ config.checkRemark }}</span>
          </li>
        </ul>
      </div>
    </el-dialog>
    <Pay v-if="payVisible" ref="Pay" @refreshDataList="init"/>
  </div>
</template>

<script>
import {
  memberApplyGetUserMemberList,
  memberBuildFormGetTemplate
} from "@/api/partners";
import {memberApplyInfo} from "../../api/partners";
import Pay from "@/pages/payment/pay.vue";

export default {
  name: "user-container",
  components: { Pay },
  data() {
    return {
      payVisible:false,
      btnPay:false,
      params: {
        current: 1,
        size: 999,
      },
      uType: null, //2团体 1个人
      dataForm: [],
      code: {},
      dialogVisible: false,
      detail: {},
      config: {},
      detailList: [],
      certList: [],
      memberCard: null,
      srcList: [],
      cardVisible: false,
      memberCardVisible: false,
      itemId: null,
      queryParams: {
        memberType: '',
        currentPage: 1,
        pageSize: 8
      },
      total: 0,
      title: ''
    };
  },
  mounted() {
    let user = JSON.parse(window.localStorage.getItem("user"));
    if (user.userInfo.tenant_id) {
      this.uType = 2;
    } else {
      this.uType = 1;
    }
    this.init();
  },
  methods: {
    init() {
      const self = this;
      // self.$store.commit("showLoading");
      memberApplyGetUserMemberList(self.queryParams).then((res) => {
        if (res.code === 200) {
          if (res.data.list.length > 0) {
            self.dataForm = res.data.list;
            self.total = res.data.pagination.total
            self.dataForm.map((item) => {
              item.code = item.code.replace(/\//g, "");
              item.code = JSON.parse(item.code);
            });
          }
        }else {
          this.$message({ type: "error", message: res.msg, });
        }
        // self.$store.commit("hideLoading");
      });
    },
    gotoPay(item){
      this.btnPay = true
      this.payVisible = true
      this.$nextTick(() => {
        this.$refs.Pay.init(item.orderNo,4)
        this.btnPay = false
      })
    },
    handleSizeChange(val) {
      this.queryParams.pageSize = val;
      this.init()
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val;
      this.init()
    },
    updatePartner(id, type, tenantId,dataType) {
      this.$router.push({
        path: `/partners-apply/${tenantId}`,
        query: { id, type,dataType },
      });
    },
    // 获取动态模板
    getMemberBuildFormGetTemplate(tenantId) {
      const self = this;
      self.$store.commit("showLoading");
      memberBuildFormGetTemplate(tenantId, this.uType).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            let customForms = JSON.parse(res.data.code);
            let detail = [];
            customForms.map((item) => {
              detail.push({ name: item.label, type: item.tagIcon, key: item.vModel, value: "", });
            });
            for (let key in this.detail) {
              detail.map((item) => {
                if (item.key === key) {
                  item.value = this.detail[key];
                }
              });
            }
            detail.map((item) => {
              if (item.type === "upload") {
                item.value = item.value.map((img) => {
                  return this.loadUrl(img);
                });
              }
            });
            this.detailList = detail;
          } else {
            // self.$message(res.msg)
          }
        }
        self.$store.commit("hideLoading");
      });
    },
    bigDialog(id) {
      this.itemId = id;
      this.memberCardVisible = false;
      const self = this;
      self.$store.commit("showLoading");
      memberApplyInfo(id).then((res) => {
        if (res.code === 200) {
          this.detail = JSON.parse(res.data.code);
          console.log(this.detail,3333)
          this.config = res.data;
          if (res.data.payProof) {
            this.certList.push(this.loadUrl(res.data.payProof));
          }
          this.getMemberBuildFormGetTemplate(res.data.tenantId)
          this.dialogVisible = true;
        }else {
          this.$message({ type: "error", message: res.msg, });
        }
        self.$store.commit("hideLoading");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-content {
  border: 20px solid #f0f0f0;
  border-bottom-width: 0;
  background: #fff;
  flex: 1;

  .bold {
    height: 54px;

    h2 {
      height: 54px;
      line-height: 54px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 30px;
      border-bottom: 1px solid #ececec;
    }
  }

  .empty {
    display: flex;
    justify-content: center;
    height: 400px;
    align-items: center;
  }

  .contain {
    padding: 22px 0;
    height: 90%;
    overflow-y: scroll;

    .pc-cont {
      //padding-left: 20px;
      display: flex;
      align-items: top;
      flex-wrap: wrap;
      overflow: hidden;
      min-height: 750px;
      .card {
        position: relative;
        width: 350px;
        height: 254px;
        padding: 20px;
        margin: 0 0 20px 20px;
        border-top: 6px solid #ffac70;
        background: #ffffff url("../../assets/image/vip.png") right bottom
          no-repeat;
        box-shadow: 0px 0px 20px 0px rgba(154, 154, 154, 0.14);
        border-radius: 6px;
        box-sizing: content-box;
        cursor: pointer;

        &.org {
          border-top: 6px solid #638bd6;
          background: #ffffff url("../../assets/image/vipSuccess.png") right top
            no-repeat;
        }

        &.info {
          border-top: 6px solid #d6d6d6;
          background: #ffffff url("../../assets/image/vipInfo.png") right top
            no-repeat;
        }

        .btn-unit {
          position: absolute;
          right: 20px;
          bottom: 20px;
        }

        h2 {
          height: 18px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 23px;
          padding-top: 10px;
        }

        .card-info {
          font-size: 14px;
          line-height: 15px;
          color: #999999;

          li {
            padding-bottom: 18px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
          }

          .name {
          }

          .value {
            &.expired-soon {
              margin-left: 8px;
            }

            &.expired {
              margin-left: 8px;
              color: #555555;
            }
          }

          .cert-btn {
            width: 80px;
            height: 30px;
            background: #ffffff;
            border: 1px solid #1e50ae;
            border-radius: 6px;
            color: #1e50ae;
            margin-left: 10px;
          }

          /deep/ .el-switch__label {
            color: #c8c8c8;
          }

          /deep/ .el-switch__label.is-active {
            color: #1e50ae;
          }
        }
      }
    }
  }

  .certinfo {
    position: relative;
    //padding-top: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;

    li {
      padding-bottom: 23px;
      display: flex;

      &.config {
        border-top: 1px solid #e5e5e5;
        padding-top: 20px;
      }

      &.expired-time {
        align-items: center;

        .expired-item {
          //display: flex;
          .cert-value {
            padding-right: 21px;
          }

          .expired-soon,
          .expired {
            padding-right: 10px;
          }

          .cert-btn {
            width: 80px;
            height: 30px;
            background: #ffffff;
            border: 1px solid #1e50ae;
            border-radius: 6px;
            color: #1e50ae;
          }
        }
      }

      .cert-name {
        flex-shrink: 0;
        display: inline-block;
        width: 100px;
      }

      .cert-value {
        flex: 1;
      }

      .el-image {
        width: 152px;
        height: 90px;
      }
    }
  }

  .certificationDialog {
    .bold {
      font-weight: bold;
    }
    /deep/ .el-dialog__body {
      overflow: auto;
      overflow-x: hidden;
      max-height: 70vh;
    }
    /deep/ .el-form-item__label {
      text-align: left;
    }

    /deep/ .el-dialog {
      width: 586px;
      box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
      border-radius: 10px;
    }

    /deep/ .el-dialog__header {
      text-align: left;
      font-size: 18px;
      color: #333333;
      padding-bottom: 18px;
      border-bottom: solid 1px #e5e5e5;
    }
  }

  .card-btn {
    background: #ffffff;
    border: 1px solid #1e50ae;
    border-radius: 6px;
    color: #1e50ae;
  }
}
.page-bar{
  margin-bottom: 10px;
  margin-left: -20px;
}
</style>
