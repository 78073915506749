<template>
  <div>
    <el-dialog :visible.sync="qrcodeVisible" class="small-dialog" title="扫码支付" @close="goBack">
      <div class="qr-wrapper">
        <p class="tip">打开微信扫码支付</p>
        <VueQr draggable="false" :correctLevel="3" backgroundColor="white" :logoScale="0.2" :logoMargin="5" logoBackgroundColor="white"
          :margin="10" :size="260" :text="qrcode" class="qrcode" />
        <p class="price">支付金额: <span class="danger" style="font-size: 12px">￥</span><span class="danger" style="font-size: 22px">{{ price }}</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPayOrder, getQrcode,payNative } from "@/api/pay";
import VueQr from "vue-qr";

export default {
  name: "pay-type",
  components: { VueQr },
  props: ["id", "title", "offline", "orderNumber", "type"],
  data() {
    return {
      payType: 1, //支付方式 1wx 2alipay 3线下
      payProof: null, //支付凭证 线下支付
      dialogVisible: false,
      fileList: [],
      checked: false,
      orderId: "",
      qrcode: "",
      qrcodeVisible: false,
      websock: undefined,
      price: "",
      btnLoading:false,
      orderNo: ''
    };
  },
  mounted() {
    // this.init();
  },
  destroyed() {
    if (this.websock) {
      this.websock.onclose()
    }
  },
  methods: {
    init(orderNo,payType){
      this.orderNo = orderNo
      this.payType = payType
      this.getPayNative()
    },
    // 培训支付 （暂时）
    confirmPayment() {
      if (!this.orderNo) {
        if (this.type === "member") {
          let params = {
            id: this.id,
            payType: this.payType == 3 ? 2 : 1, //支付方式 1线上 2线下
            payProof: this.payProof,
          };

          if (this.payType == 3 && !this.payProof) {
            this.$message({
              type: "warning",
              message: "请上传支付凭证！",
            });
          } else {
            if (this.payType == 3) {
              getPayOrder(params).then((res) => {
                if (res.code === 200) {
                  console.log("res", res);
                  // self.$store.commit('hideLoading')
                }
              });
              this.$router.push({
                path: "/result",
                query: { type: "success", title: this.title },
              });
            } else {
              getPayOrder(params).then((res) => {
                if (res.code === 200) {
                  this.orderId = res.data.orderNo;
                  //如果是线上才调二维码
                  if (res.data.payType == 1) {
                    this.getQrcode();
                  }
                }
              });
            }
          }
        } else if (this.type === "course") {
          this.$emit("getOrderNumber", this.payType);
        }
        else if (this.type === "events") {
          this.getQrcode();
          this.initWebSocket();
        }
      } else {
        this.getQrcode();
      }
    },
    getQrcode() {
      this.$store.commit("showLoading");

      let _orderNumber = this.orderNo ? this.orderNo : this.orderId;

      getQrcode({ orderNumber: _orderNumber, payType: this.payType }).then(
        (res) => {
          if (res.code === 200) {
            //价格为0的情况返回pay_ok
            if (res.data.pay_ok) {
              this.$message({
                type: "success",
                message: "申请成功",
                onClose: () => {
                  if (this.type === "member") {
                    this.$router.push({
                      path: "/result",
                      query: { type: "success", title: this.title },
                    });
                  } else if (this.type === "events" || this.type === "train") {
                    let params = { active: 4, type: "success" };
                    this.$emit("stepNum", params);
                  }
                },
              });
            } else {
              this.initWebSocket();
              this.qrcode = res.data.codeUrl;
              this.price = res.data.price;
              this.qrcodeVisible = true;
            }
          }
          this.$store.commit("hideLoading");
        }
      );
    },
    getPayType(type) {
      this.payType = type;
      this.checked = true;
    },
    goBack() {
      this.$emit("refreshDataList");
    },

    //webSocket
    initWebSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        const wsUrl = process.env.VUE_APP_BASE_WSS;
        this.websock = new WebSocket(wsUrl);
        this.websock.onmessage = this.onMessage;
        this.websock.onopen = this.onOpen;
        this.websock.onerror = this.onError;
        this.websock.onclose = this.onClose;
      }
    },
    onOpen() {
      //连接建立之后执行send方法发送数据
      let params = {
        method: "pay",
        orderNo: this.orderNo ? this.orderNo : this.orderId,
      };
      this.onSend(JSON.stringify(params));
    },
    onError() {
      //连接建立失败重连
      setTimeout(() => {
        console.log("连接建立失败重连");
        this.initWebSocket();
      }, 1000);
    },
    onMessage(e) {
      //数据接收
      let reData = JSON.parse(e.data);
      console.log(reData.code)
      if (reData.code == 202) {
        this.$message({ type: "warning", message: "支付超时，请重新支付", });
        this.websock.close();
        this.qrcode = null;
        this.qrcodeVisible = false;
        setTimeout(() => {
          if( this.payType == 5 ||this.payType == 4) {//5订单页面支付,4我的会员页面支付
            this.$emit("refreshDataList");
          }else {
            this.$router.push({ path: "/result", query: { type: "fail" }, });
          }
        }, 1500);
      }

      if (reData.code == 201) {
        this.$message({ type: "success", message: "支付成功", });
        this.websock.close();
        this.qrcodeVisible = false;
        setTimeout(() => {
          if( this.payType == 5 ||this.payType == 4) {//5订单页面支付,4我的会员页面支付
            this.$emit("refreshDataList");
          }else {
            this.$router.push({path: "/result", query: { type: "success", title: this.payType==1?'我的会员':'我的订单' },});
          }
        }, 1500);
      }
    },
    onSend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    onClose(e) {
      //关闭
      console.log("断开连接", e);
    },
    lastStep() {
      this.$emit("stepNum");
    },
    getPayNative(){
      let params = {
        orderNo: this.orderNo,
        payType: 1,
        appId:'123456',//随便传值
        openId:'123456',
        tenantModel:2,
      }
      this.btnLoading = true
      payNative(params).then(res=>{
        if(res.code == 200){
          this.qrcode = res.data.codeUrl;
          this.price = res.data.price;
          this.qrcodeVisible = true;
          this.btnLoading = false
          this.initWebSocket();
        }else {
          this.$message(res.msg);
          this.btnLoading = false
        }
        this.$store.commit("hideLoading");
      }).catch(()=>{
        this.btnLoading = false
        this.$store.commit("hideLoading");
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.partners-info {
  border-top: 1px solid #ececec;
  font-size: 14px;
  color: #555;
  padding: 16px;

  .title {
    font-size: 18px;
    color: #333333;
    padding-bottom: 10px;
  }

  .pay-type-group {
    display: flex;
    flex-direction: column;

    ::v-deep .el-radio__label {
      display: inline-flex;
      align-items: center;
    }

    .icon-weixinzhifu {
      font-size: 20px;
      display: inline-block;

      color: #46bb36;
      padding-right: 5px;

      &.checked {
        transform: scale(1.5);
        transition: all 0.5s;
      }
    }

    .icon-zhifubao {
      font-size: 20px;

      display: inline-block;
      color: #02a9f1;
      padding-right: 5px;

      &.checked {
        transform: scale(1.5);
        transition: all 0.5s;
      }
    }

    .icon-yinhangqia {
      font-size: 20px;

      display: inline-block;
      color: #ffc11a;
      padding-right: 5px;

      &.checked {
        transform: scale(1.5);
        transition: all 0.5s;
      }
    }

    .btn-pay {
      text-align: center;
    }

    .el-radio {
      height: 40px;
      line-height: 40px;
    }

    .offline-radio {
      display: flex;
      align-items: baseline;
    }

    .off-line {
      display: flex;
    }

    ::v-deep.el-button {
      border: 1px solid #999999;
      border-radius: 6px;
      margin-left: 18px;
      padding: 9px;
    }

    ::v-deep .el-radio__input.is-checked + .el-radio__label {
      color: #555;
    }
  }

  .btn-bar {
    padding-top: 48px;
    text-align: center;

    .el-button {
      width: 100px;
      height: 38px;
    }
  }

  ::v-deep .el-dialog {
    width: 548px;
    box-shadow: 0 9px 40px 0 rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }
}

.small-dialog {
  ::v-deep .el-dialog__header {
    border-bottom: solid 1px #e5e5e5;
  }
}

.qr-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .qrcode {
    width: 300px;
    padding-bottom: 12px;
  }

  .tip {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .price {
    margin-top: 10px;
    font-size: 14px;
  }
}
</style>
